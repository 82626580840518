import { Input } from "@chakra-ui/react"
import { useContext, useCallback, memo } from "react"
import { ModalSheetContext } from "react-shared"
import { Vegetal } from "soverdi-api-models"
import { MapContext } from "../../contexts/map.context"
import { ModalLockContext } from "../../contexts/modal-lock.context"
import { useTranslation } from "react-i18next"
import { InputProps } from "@class-former/react"

function RemplacementInputComponent({ value, onChange }: InputProps<number>) {

    const { t } = useTranslation()
    const { onPrompt, onClosePrompt } = useContext(ModalSheetContext)
    const { mapHandle } = useContext(MapContext)
    const { setLock } = useContext(ModalLockContext)

    const selectReplacement = useCallback(() => {
        setLock(true)
        mapHandle?.clear()
        const subscription = mapHandle?.events.onSelect.subscribe({
            next: params => {
                if (params.clazz !== Vegetal || params.ids.length !== 1) return
                onChange(params.ids[0])
                subscription?.unsubscribe()
                onClosePrompt()
                setLock(false)
            }
        })
        onPrompt(t("VegetalForm.selectReplacement"), { subtext: t("VegetalForm.selectReplacementSubtext") }).then(() => {
            setLock(false)
            subscription?.unsubscribe()
        })
    }, [t, mapHandle, onChange, onClosePrompt, onPrompt, setLock])

    return (
        <Input readOnly onClick={selectReplacement} data-testid="remplacementid-input" type="number" onChange={() => null} value={value} />
    )
}

export const RemplacementInput = memo(RemplacementInputComponent) as typeof RemplacementInputComponent
